import axios from 'axios'

export function getUrlKey(name, url) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ''])[1].replace(/\+/g, '%20')) || null
}

export function getRandomColor() {
  /*随机获取颜色*/
  var r = Math.floor(Math.random() * 256)
  var g = Math.floor(Math.random() * 256)
  var b = Math.floor(Math.random() * 256)
  return 'rgb(' + r + ',' + g + ',' + b + ')'
  //return '#' + Math.floor(Math.random() * 0xffffff).toString(16);
}
/**
 * 保留2位小数点
 * @param {number} num
 * @returns
 */
export const numToFixed = num => {
  if (!num) return '0.00'
  if (isNaN(Number(num))) return num
  num = num.toFixed(2)
  if (num.includes('.')) {
    return num.slice(0, -3).replace(/(\d)(?=(\d{3})+$)/g, '$1,') + num.slice(-3)
  }
  return num.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
}

/**
 *
 * @param {*} fn  是我们需要包装的事件回调
 * @param {*} delay 是每次推迟执行的等待时间
 */
export const debounce = (fn, delay = 1000) => {
  // 定时器
  let timer = null
  // 将debounce处理结果当作函数返回
  return function() {
    // 保留调用时的this上下文
    let context = this
    // 保留调用时传入的参数
    let args = arguments
    // 每次事件被触发时，都去清除之前的旧定时器
    // console.log(timer)
    if (timer) {
      clearTimeout(timer)
    }
    // 设立新定时器
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, delay)
  }
}

export function getBlob(data) {
  return new Promise(function(r, j) {
    axios({
      // url: data.url,
      url: `https://mml-kj.oss-cn-shenzhen.aliyuncs.com/%E8%AE%A2%E5%8D%95%E7%BB%9F%E8%AE%A1.xls`,
      method: 'get',
      responseType: 'blob',
      params: {},
      headers: {}
    })
      .then(res => {
        var blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'
        })
        var filename = data.name
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = filename // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
        r()
      })
      .catch(err => {
        console.log(err.message)
        j()
      })
  })
}

export default {
  numToFixed
}
