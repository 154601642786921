<template>
  <div class="Home">
    <router-view />
    <div style="height: 50px;"></div>
    <van-tabbar route v-model="active">
      <van-tabbar-item replace to="/home/analysis" icon="home-o">数据分析</van-tabbar-item>
      <van-tabbar-item replace to="/home/mine" icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      analysisList: [],
      businessList: [],
      active: 0,
    };
  },
  methods: {},
  created() {
    const modules = JSON.parse(window.sessionStorage.getItem("modules"));
    modules.forEach((value) => {
      if (value.moduleType === "0") {
        this.analysisList.push(value);
      } else {
        this.businessList.push(value);
      }
    });
    window.sessionStorage.setItem(
      "analysisList",
      JSON.stringify(this.analysisList)
    );
    window.sessionStorage.setItem(
      "businessList",
      JSON.stringify(this.businessList)
    );
    this.$router.replace("/home/analysis");
  },
};
</script>
<style scoped>
.Home {
  height: 100%;
}
</style>
