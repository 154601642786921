export default {
    getBeforeDate: function (beforeDayNumber) {
        /**
         * 获取当前日前多少天
         * 返回格式: YYYY-mm-dd
         * 例子: 当日为: 2020-11-27
         * 返回日期为: 2020-11-01
         * */
        let n = beforeDayNumber;
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        if(day <= n) {
            if(month > 1) {
                month = month - 1;
            } else {
                year = year - 1;
                month = 12;
            }
        }
        d.setDate(d.getDate() - n);
        year = d.getFullYear();
        month = d.getMonth() + 1;
        day = d.getDate();
        return year + "/" + (month < 10 ? ('0' + month) : month) + "/" + (day < 10 ? ('0' + day) : day);
    },
    getCurrentMonthFirstDay: function () {
        /**
         * 获取当前月的第一天
         * 返回格式: YYYY-mm-dd
         * 例子: 当日为: 2020-11-27
         * 返回日期为: 2020-11-01
         * */
        let date = new Date();
        date.setDate(1);
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (month < 10) {
            month = '0' + month
        }
        if (day < 10) {
            day = '0' + day
        }
        return date.getFullYear() + '/' + month + '/' + day;
    },
    getCurrentMonthLastDay: function () {
        /**
         * 获取当前月的最后一天
         * 返回格式: YYYY-mm-dd
         * 例子: 当日为: 2020-11-27
         * 返回日期为: 2020-11-30
         * */
        let date = new Date();
        let currentMonth = date.getMonth();
        let nextMonth = ++currentMonth;
        let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
        let oneDay = 1000 * 60 * 60 * 24;
        let lastTime = new Date(nextMonthFirstDay - oneDay);
        let month = lastTime.getMonth() + 1;
        let day = lastTime.getDate();
        if (month < 10) {
            month = '0' + month
        }
        if (day < 10) {
            day = '0' + day
        }
        return date.getFullYear() + '/' + month + '/' + day;
    },
    formatDate: function (date) {
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if(month < 10) {
            month = '0' + month;
        }
        if(day < 10) {
            day = '0' + day;
        }
        return `${date.getFullYear()}/${month}/${day}`;
    },
}
