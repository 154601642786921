<template> </template>

<script>
import { getUrlKey } from '@/utils/common'

export default {
  name: 'Login',
  mounted() {
    if (getUrlKey('openid', window.location.href)) {
      if (window.sessionStorage.getItem('modules')) {
        return
      }
      this.postRequest('/wechat/miniProgramLogin', {
        openid: getUrlKey('openid', window.location.href),
        token: getUrlKey('token', window.location.href)
      }).then(resp => {
        console.log(resp)
        if (resp) {
          let data = resp.data
          if (data.selRole === 0) {
            //单角色处理
            if (data.modules && data.modules.length > 0) {
              window.sessionStorage.setItem('modules', JSON.stringify(data.modules))
              window.sessionStorage.setItem('mobileOfficeMiniTOKEN', JSON.stringify(data.token))
              this.$router.replace('/home')
            } else {
              this.$toast.fail('当前账号还未分配资源，请联系管理员')
            }
          } else {
            //多角色处理(暂时忽略)
          }
        }
      })
    } else {
      this.$router.replace('/mini')
    }
  }
}
</script>

<style scoped></style>
