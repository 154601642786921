import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/views/Login'
import Business from '@/components/Business'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'analysis',
        name: 'Analysis',
        component: () => import('../views/Analysis')
      },
      {
        path: 'mine',
        name: 'mine',
        component: () => import('../views/mine')
      },
      {
        path: 'business',
        name: 'Business',
        component: Business
      }
    ]
  },
  {
    path: '/mini',
    name: 'MiniConfirm',
    component: () => import('../views/MiniConfirm')
  },
  {
    path: '/analysis/1001',
    name: 'Analysis1001',
    component: () => import('../components/Analysis/Analysis1001')
  },
  {
    path: '/analysis/1002',
    name: 'Analysis1002',
    component: () => import('../components/Analysis/Analysis1002')
  },
  {
    path: '/analysis/1003',
    name: 'Analysis1003',
    component: () => import('../components/Analysis/Analysis1003')
  },
  {
    path: '/analysis/1004',
    name: 'Analysis1004',
    component: () => import('../components/Analysis/Analysis1004')
  },
  {
    path: '/analysis/1005',
    name: 'Analysis1005',
    component: () => import('../components/Analysis/Analysis1005')
  },
  {
    path: '/analysis/1006',
    name: 'Analysis1006',
    component: () => import('../components/Analysis/Analysis1006')
  },
  {
    path: '/analysis/1007',
    name: 'Analysis1007',
    component: () => import('../components/Analysis/Analysis1007')
  },
  {
    path: '/analysis/1008',
    name: 'Analysis1008',
    component: () => import('../components/Analysis/Analysis1008')
  },
  {
    path: '/analysis/1009',
    name: 'Analysis1009',
    component: () => import('../components/Analysis/Analysis1009')
  },
  {
    path: '/analysis/1010',
    name: 'Analysis1010',
    component: () => import('../components/Analysis/Analysis1010')
  },
  {
    path: '/analysis/1011',
    name: 'Analysis1011',
    component: () => import('../components/Analysis/Analysis1011')
  },
  {
    path: '/analysis/1012',
    name: 'Analysis1012',
    component: () => import('../components/Analysis/Analysis1012')
  },
  {
    path: '/analysis/1013',
    name: 'Analysis1013',
    component: () => import('../components/Analysis/Analysis1013')
  },
  {
    path: '/analysis/intradayAdd',
    name: 'intradayAdd',
    component: () => import('../views/intradayAdd')
  },
  {
    path: '/analysis/addInstallgdl',
    name: 'addInstallgdl',
    component: () => import('../views/addInstallgdl')
  },
  {
    path: '/analysis/addrepairgdl',
    name: 'addrepairgdl',
    component: () => import('../views/addrepairgdl')
  },
  {
    path: '/analysis/payDay',
    name: 'payDay',
    component: () => import('../views/payDay')
  },
  {
    path: '/analysis/allNetWork',
    name: 'allNetWork',
    component: () => import('../views/allNetWork')
  },
  {
    path: '/analysis/allNetWorkMonth',
    name: 'allNetWorkMonth',
    component: () => import('../views/allNetWorkMonth')
  },
  {
    path: '/masterFinished/index',
    name: 'index',
    component: () => import('../views/masterFinished/index')
  },
  {
    path: '/masterFinished/install',
    name: 'install',
    component: () => import('../views/masterFinished/install.vue')
  },
  {
    path: '/masterFinished/installDetail',
    name: 'installDetail',
    component: () => import('../views/masterFinished/install-detail.vue')
  },
  {
    path: '/masterFinished/installDetailMonth',
    name: 'installDetailMonth',
    component: () => import('../views/masterFinished/install-detail-month.vue')
  },
  {
    path: '/masterFinished/installDetailWebsit',
    name: 'installDetailWebsit',
    component: () => import('../views/masterFinished/install-detail-websit.vue')
  },
  {
    path: '/masterFinished/repaire',
    name: 'repaire',
    component: () => import('../views/masterFinished/repaire.vue')
  },
  {
    path: '/masterFinished/repaireDetail',
    name: 'repaireDetail',
    component: () => import('../views/masterFinished/repaire-detail.vue')
  },
  {
    path: '/masterFinished/repaireDetailMonth',
    name: 'repaireDetailMonth',
    component: () => import('../views/masterFinished/repaire-detail-month.vue')
  },
  {
    path: '/masterFinished/repaireDetailWebsit',
    name: 'repaireDetailWebsit',
    component: () => import('../views/masterFinished/repaire-detail-websit.vue')
  },
  {
    path: '/order/today',
    name: 'today',
    component: () => import('../views/order/today.vue')
  },
  {
    path: '/order/tomorrow',
    name: 'tomorrow',
    component: () => import('../views/order/tomorrow.vue')
  },
  {
    path: '/analysis/websiteDetail',
    name: 'websiteDetail',
    component: () => import('../views/websiteDetail.vue')
  },
  {
    path: '/reservation/index',
    name: 'reservation',
    component: () => import('../views/reservation/index.vue')
  },
  {
    path: '/reservation/website',
    name: 'website',
    component: () => import('../views/reservation/website.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
