<template>
  <div id="Business">
    <h1>业务模块</h1>
  </div>
</template>

<script>
export default {
  name: "Business"
}
</script>

<style scoped>

</style>