import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/styles/index.scss' // global css
import 'font-awesome/css/font-awesome.css'
import echarts from '@/plugin/echartsUI'
import dateUtils from '@/utils/dateUtils'

Vue.use(Vant)

import { numToFixed } from '@/utils/common.js'
Vue.prototype.$numToFixed = numToFixed

import { postRequest, getRequest, postRequestPHP } from '@/utils/api'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.postRequest = postRequest
Vue.prototype.postRequestPHP = postRequestPHP
Vue.prototype.getRequest = getRequest
Vue.prototype.dateUtils = dateUtils

//过滤器
Vue.filter('numToFixed', function(num) {
  if (!num) return '0.00'
  if (isNaN(Number(num))) return num
  num = num.toFixed(2)
  if (num.includes('.')) {
    return num.slice(0, -3).replace(/(\d)(?=(\d{3})+$)/g, '$1,') + num.slice(-3)
  }
  return num.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
})

router.beforeEach((to, from, next) => {
  let analysisList = JSON.parse(window.sessionStorage.getItem('analysisList'))
  if (to.path == '/home/analysis' && analysisList && analysisList.length == 0) {
    next('/home/business')
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
