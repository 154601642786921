import axios from 'axios'
import { Notify } from 'vant'
// import qs from 'qs'

const token = JSON.parse(sessionStorage.getItem('mobileOfficeMiniTOKEN'))

//响应拦截器
axios.interceptors.response.use(
  success => {
    //业务逻辑错误
    // if (success.code == 2) {
    //   Notify({ type: 'danger', message: success.msg })
    // }

    if (success.status && success.status == 200) {
      if (success.data.code == 500 || success.data.code == 401 || success.data.code == 403) {
        Notify({ type: 'danger', message: success.data.message })
        return
      }
      if (success.data.message) {
        Notify({ type: 'success', message: success.data.message })
      }
    }
    return success.data
  },
  error => {
    // alert(error)
    if (error.response.code == 501 || error.response.code == 404) {
      Notify({ type: 'danger', message: '服务器丢失' })
    } else if (error.response.code == 403) {
      Notify({ type: 'danger', message: '权限不足，请联系管理员！' })
    } else if (error.response.code == 401) {
      Notify({ type: 'danger', message: '未登录,请登录' })
    } else {
      if (error.response.data.message) {
        Notify({ type: 'danger', message: error.response.data.message })
      } else {
        Notify({ type: 'danger', message: '未知错误！' })
      }
    }
    return
  }
)

// 添加请求拦截器
axios.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    // alert(JSON.stringify(config))

    // console.log(config)
    // if (token) {
    //   config.headers['x-token'] = token
    // }
    // config.headers['Access-Control-Allow-Origin'] = '*'
    return config
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

let base = '/api'

//传送json格式的post请求
export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params
  })
}
export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${base}${url}`,
    params: params
  })
}
export const postRequestPHP = (url, params) => {
  return axios({
    // baseURL: 'https://admin.zfire.top',
    // baseURL: 'https://pgxtadm.greeapps.com',
    method: 'post',
    url: `/php${url}`,
    // url: `${url}`,
    data: params
  })
}
